<template>
  <div question-filter>
    <div v-show="loading" class="filter-position-wrapper">
      <div v-for="i in 7" class="skeleton-item" :key="`position-item-skeleton-${i}`" />
    </div>
    <simple-tabs @input="(v) => $emit('input', v)" :value="value" class="filter-position-wrapper" v-show="!loading">
      <simple-tab tag="button" v-for="(lane) in ls" :key="lane.value">{{ lane.text }}</simple-tab>
    </simple-tabs>
  </div>
</template>

<script>
import SimpleTabs from '@/views/components/coaching/SimpleTabs.vue';
import SimpleTab from '@/views/components/coaching/SimpleTab.vue';

export default {
  components: { SimpleTab, SimpleTabs },
  props: {
    lanes: {
      type: Array,
      default: () => [],
    },
    value: {
      type: [String, Number, Boolean],
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ls() {
      return /** @type{QuestionCategoryLane[]} */ this.lanes;
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';
[question-filter] {
  .filter-position-wrapper {.flex;.flex-wrap(wrap);}
  .skeleton-item { .skeleton-bg;.block;.wh(76, 48);.br(4);}
  [simple-tab] {.wh(76, 40);.fs(12);.br(4);
    border: none;
    &:not(.active) {.bgc(#fff);.c(#191919);border: solid 1px #ebebf0;}
    &.active { .bgc(#8300ff);.c(#fff);}
    &:hover:not(.active) { .bgc(#ebebf0);.c(#191919);}
  }
}
</style>
