<template>
  <modal-filter-layout modal-filter-lane @close="onClose" @save="onSave" @reset="onReset">
    <template #title-extra>포지션</template>
    <template>
      <filter-lane :lanes="lanes" :loading="loadState !== 'success'" v-model="lane" />
    </template>
  </modal-filter-layout>
</template>
<script>
import FilterLane from '@/views/components/coaching/filter/FilterLane.vue';
import ModalFilterLayout from '@/views/components/coaching/filter/ModalFilterLayout.vue';

export default {
  components: { ModalFilterLayout, FilterLane },
  props: {
    options: { type: Object, default: () => ({ defaultLane: 'ALL' }) },
  },
  data: () => ({
    lane: '',
    lanes: /** @type {QuestionCategoryLane[]} */ [],
    loadState: /** @type {LoadState} */ 'init',
  }),
  methods: {
    onClose() {
      this.$emit('reject', 'no_lane');
    },
    onSave() {
      this.$emit('resolve', this.lane);
    },
    onReset() {
      this.$emit('resolve', '');
    },
  },
  async beforeMount() {
    if (this.options?.defaultLane) this.lane = this.options?.defaultLane;
    this.loadState = 'loading';
    this.lanes = await this.$services.coaching.getCategories('LOL_LANE');
    this.loadState = 'success';
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[modal-filter-lane] {
  [simple-tab]:not(:nth-child(4n)) {.mr(8);}
  [simple-tab]:not(:nth-last-child(-n+4)) {.mb(8)}
}
</style>
